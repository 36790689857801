import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

import * as S from '../styles/workouts.styles'
import * as I from '../components/shared/Icons'
import { navigate } from 'gatsby'
import { WORKOUTS_ROUTE } from '../constants/routes'
import Layout from '../templates/Layout'
import WorkoutItem from '../components/WorkoutsRow/Components/WorkoutItem'
import * as session from '../services/session'
import { HOME_ROUTE } from '../constants/routes'
import { Section } from '../components/shared'
import { GenericWorkout } from '../types/generic-workout'
import { SECONDARY } from '../constants/colors'
import { withErrorHandler } from '../components/errorHandler'

interface Props {
  location: { state: { type: string | null; workouts: GenericWorkout[] } }
}

const WorkoutsSeeAll: React.FC<Props> = ({ location }: Props) => {
  session.redirectIfNotLoggedIn(HOME_ROUTE)

  const goBack = () => navigate(WORKOUTS_ROUTE)

  const renderWorkout = (workout: GenericWorkout) => (
    <div key={workout.id} style={{ maxWidth: 360 }}>
      <WorkoutItem workout={workout} marginBottom={36} />
      <div style={{ paddingBottom: 0 }} />
    </div>
  )

  return (
    <Layout>
      <Section>
        {location.state ? (
          <>
            <S.SeeAllHeader
              style={{
                cursor: 'pointer',
              }}
              onClick={() => goBack()}
            >
              <I.ChevronLeft lg={true} color={SECONDARY} />
              {location.state.type && (
                <S.SeeAllTitle>{location.state.type}</S.SeeAllTitle>
              )}
            </S.SeeAllHeader>
            <S.SeeAllContainer>
              {location.state.workouts &&
                location.state.workouts.map(w => renderWorkout(w))}
            </S.SeeAllContainer>
          </>
        ) : (
          <CircularProgress size={40} color="secondary" />
        )}
      </Section>
    </Layout>
  )
}

export default withErrorHandler(WorkoutsSeeAll)
