import React, { useState } from 'react'
import { connect } from 'react-redux'
import { navigate } from 'gatsby'
import moment from 'moment'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import * as S from '../../../styles/workouts.styles'
import * as I from '../../shared/Icons'
import {
  WORKOUT_VIDEO_ROUTE,
  TRAINER_UPLOAD_VIDEO,
  TRAINER_WORKOUT_VIDEO_ROUTE,
} from '../../../constants/routes'
import { WHITE } from '../../../constants/colors'
import { onFetchWorkouts as onFetchWorkoutsAction } from '../../../redux/actions'
import { GenericWorkout } from '../../../types/generic-workout'
import { RootState } from '../../../redux/reducers/rootReducers'
import * as session from '../../../services/session'
import {
  fetchUpdateWorkout,
  fetchDeleteWorkout,
} from '../../../util/fetch/workouts'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { isMobile } from 'react-device-detect'

interface WorkoutItemProps {
  workout: GenericWorkout
  marginBottom?: number
  isEditable?: boolean
}

export const imagePlaceholder =
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzSc0E_-ezcw1juku7x_q9rIVtGDEFGDsZnA&usqp=CAU'

const convertDate = (dateString: string): string => {
  const date1 = moment(dateString).format('dddd MMM D')
  const date2 = moment(dateString).format('h:mm A')
  return `${date1}, at ${date2}`
}

const WorkoutItem: React.FC<Props> = (props: Props) => {
  const {
    workout,
    marginBottom,
    userHasAccess,
    isEditable,
    onFetchWorkouts,
  } = props
  const [show, setShow] = useState<boolean>(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [isPublishing, setIsPublishing] = useState<boolean>(false)

  const cleanedThumbnail = workout.thumbnail
    ? workout.thumbnail.replace(' ', '%20')
    : ''

  const hasAccess = session.isTrainer() || userHasAccess

  const goToWorkoutVideo = () => {
    if (isEditable) {
      navigate(TRAINER_WORKOUT_VIDEO_ROUTE(workout.id))
      return null
    }
    if (hasAccess) {
      const { id } = workout
      navigate(WORKOUT_VIDEO_ROUTE(id))
    }
  }

  const onDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    fetchDeleteWorkout(workout.id)
      .then(() => {
        setOpenDeleteDialog(false)
        onFetchWorkouts()
      })
      .catch(err => {
        console.log('err', err)
      })
  }

  const onPublish = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setIsPublishing(true)

    fetchUpdateWorkout(workout.id, {
      published: !workout.published,
    })
      .then(() => {
        // location.reload()
        onFetchWorkouts()
      })
      .catch(err => {
        console.log('err', err)
      })
      .finally(() => setIsPublishing(false))
  }

  const onEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    navigate(TRAINER_UPLOAD_VIDEO, { state: { editWorkoutData: workout } })
  }

  return (
    <>
      <S.WorkoutWraper
        id={`workout${workout.title}`}
        style={{
          marginBottom: marginBottom || 0,
        }}
        onClick={() => goToWorkoutVideo()}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        {workout.date && (
          <S.WorkoutInfoWrap>
            <S.WorkoutInfoSecondary>
              {convertDate(workout.date)}
            </S.WorkoutInfoSecondary>
          </S.WorkoutInfoWrap>
        )}
        <S.WorkoutWrap>
          <S.WorkoutThumbnail src={cleanedThumbnail || imagePlaceholder} />
          {!hasAccess && (
            <S.LockOverlay>
              <I.Lock color={WHITE} />
            </S.LockOverlay>
          )}
          {hasAccess && show && (
            <S.PlayButton>
              <I.Play fill={WHITE} color={WHITE} />
            </S.PlayButton>
          )}
        </S.WorkoutWrap>
        <S.WorkoutInfoWrap
          style={{ overflow: isMobile ? 'hidden' : 'initial' }}
        >
          {!isMobile && (
            <S.TrainerImage src={workout.trainer.profilePictureURL} />
          )}
          <div style={{ width: '80%' }}>
            <S.WorkoutInfo>{workout.title}</S.WorkoutInfo>
            <S.WorkoutInfoSecondary>{`${workout?.length || 35}m | ${
              workout.trainerName
            }`}</S.WorkoutInfoSecondary>
          </div>
        </S.WorkoutInfoWrap>
      </S.WorkoutWraper>
      {isEditable && (
        <>
          <Button
            variant="outlined"
            color={workout.published ? 'secondary' : 'primary'}
            disableElevation
            style={{ marginTop: 10, marginRight: 0, marginLeft: 8, width: 90 }}
            onClick={onPublish}
            id={`${workout.published ? 'workoutUnpublish' : 'workoutPublish'}${
              workout.title
            }`}
          >
            {isPublishing ? (
              <CircularProgress size="1.5rem" />
            ) : workout.published ? (
              'Unpublish'
            ) : (
              'Publish'
            )}
          </Button>
          <Button
            variant="text"
            color="secondary"
            disableElevation
            style={{ marginTop: 10 }}
            onClick={onEdit}
            id={`workoutEdit${workout.title}`}
          >
            Edit
          </Button>
          <Button
            variant="text"
            color="secondary"
            disableElevation
            style={{ marginTop: 10 }}
            onClick={() => setOpenDeleteDialog(true)}
            id={`workoutDelete${workout.title}`}
          >
            Delete
          </Button>
          <Dialog
            open={openDeleteDialog}
            onClose={() => setOpenDeleteDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Delete workout ?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this workout ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpenDeleteDialog(false)}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={onDelete}
                color="primary"
                autoFocus
                id={`deleteWorkoutConfirm${workout.title}`}
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  userHasAccess: state.ui.userAccessReducer.userHasAccess,
})

const mapDispatchToProps = {
  onFetchWorkouts: onFetchWorkoutsAction.request,
}

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WorkoutItemProps

export default connect(mapStateToProps, mapDispatchToProps)(WorkoutItem)
