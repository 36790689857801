import styled from 'styled-components'
import { BACKGROUND, PRIMARY, SECONDARY } from '../constants/colors'
import { LARGE_DESKTOP, minWidth } from '../constants/measurements'

export const Container = styled.div`
  background-color: ${BACKGROUND};
`

export const Title = styled.div`
  font-size: 22px;
  color: ${SECONDARY};
  letter-spacing: 1px;
  text-align: left;
  margin-right: 20px;
`

export const SeeAll = styled.div`
  font-size: 18px;
  color: ${PRIMARY};
  letter-spacing: 1px;
  text-align: left;
  cursor: pointer;
`

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  margin-left: 8px;
  margin-bottom: 20px;
  max-height: 45px;
`

export const WorkoutInfo = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  padding-top: 4px;
  letter-spacing: 0.5px;
  color: ${PRIMARY};
  padding-left: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 4px;
  overflow-wrap: break-word;
`

export const WorkoutInfoSecondary = styled.div`
  font-size: 16px;
  letter-spacing: 0.5px;
  color: ${PRIMARY};
  padding-left: 4px;
`

export const WorkoutThumbnail = styled.img`
  object-fit: cover;
  margin-bottom: 0;
  opacity: 0.9;
  border-radius: 4px;

  ${minWidth(LARGE_DESKTOP)} {
    height: 225px;
  }

  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`

export const PlayButton = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 995;
  cursor: pointer;
`

export const LockOverlay = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 995;
  cursor: pointer;
`

export const WorkoutWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transform: translate3d(0px, 0, 0);
  margin: 0px 8px;
`

export const WorkoutWraper = styled.div``

export const WorkoutInfoWrap = styled.div`
  margin: 0px 8px;
  margin-top: 10px;
  cursor: pointer;
  display: flex;
`

export const TrainerImage = styled.img`
  width: 36px;
  height: 36px;
  margin-right: 10px;
  border-radius: 50%;
  margin-top: 4px;
  margin-left: 3px;
`

export const WorkoutsContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  padding-left: 50px;
  padding-right: 50px;
`

export const WorkoutRow = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 36px;
`

export const FilterWrap = styled.div`
  margin-bottom: -10px;
`

export const CustomArrowWrap = styled.div<{ isLargerRow: boolean }>`
  height: ${({ isLargerRow }) => (isLargerRow ? '85%' : '75%')};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
`

export const ChevronContainer = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
`

export const SeeAllHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 46px;
  cursor: pointer;
`

export const SeeAllTitle = styled.div`
  font-size: 26x;
  color: ${PRIMARY};
  letter-spacing: 1px;
  font-weight: 500;
  text-align: left;
  margin-left: 16px;
`

export const SeeAllContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`

export const CarouselImage = styled.img`
  width: 100%;
  height: auto;
  min-height: 400px;
  object-fit: cover;
  margin-bottom: 0;
`
